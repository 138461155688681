let BACKEND_SERVER = null; // oceands_backend_url
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "http://localhost:5000";
}

let WebRTC_SERVER = null; // go2rtc_url
if (process.env.REACT_APP_WebRTC_SERVER) {
  WebRTC_SERVER = process.env.REACT_APP_WebRTC_SERVER;
} else {
  WebRTC_SERVER = "https://localhost:8443";
}

let Python_WebRTC_SERVER = null; // zcost_server_url
if (process.env.REACT_APP_Python_WebRTC_SERVER) {
  Python_WebRTC_SERVER = process.env.REACT_APP_Python_WebRTC_SERVER;
} else {
  Python_WebRTC_SERVER = "http://localhost:8080"; // https://test.cloud249.com
}

let db_instance = null; // oceands_db
if (process.env.REACT_APP_DB_INSTANCE) {
  db_instance = process.env.REACT_APP_DB_INSTANCE;
} else {
  db_instance = 'default';
}

const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/dashboard",
  API_SERVER: BACKEND_SERVER,
  WebRTC_SERVER: WebRTC_SERVER,
  Python_WebRTC_SERVER: Python_WebRTC_SERVER,
  db_instance: db_instance,
};

export default config;

